import React, { useEffect } from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import PouchDB from "pouchdb";
import { useSetRecoilState } from "recoil";
import { useDoc } from "use-pouchdb";
import tw, { styled } from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { sidebaropen } from "./../state/custom";
import Datalayer from "./../utilities/datalayer";

const localDB = new PouchDB('delforno', { revs_limit: 1 });
const dataCache = {};

export default function Header() {

    const { doc: cart, loading: cartLoading } = useDoc('cart');
    const { doc: customer, loading: customerLoading } = useDoc('customer');

    const setSidebarOpen = useSetRecoilState(sidebaropen);

    const toggleDrawer = () => {
        setSidebarOpen(true);
    }

    useEffect(() => {

        if (!dataCache[customer] && customer && customer.email) {

            var dl = new Datalayer();
            dl.get('/loyaltypoints/getloyaltypointscustomer?email=' + customer.email)
                .then(response => response.data)
                .then(fetchedData => {
                    dataCache[customer] = fetchedData;

                    let points = fetchedData.points;

                    return localDB.upsert('customer', (doc) => {
                        doc.dough = points;
                        return doc;
                    });

                });

        } else {
            // return localDB.upsert('customer', (doc) => {
            //     doc.dough = 0;
            //     return doc;
            // });
        }
    }, [customer]);

    if (cartLoading || customerLoading) {
        return <div style={{ backgroundColor: '#000' }}>loading ...</div>
    }

    return (
        <HeaderContainer>
            <GridContainer>
                <IconContainerRelative onClick={() => toggleDrawer()}>
                    <FontAwesomeIcon icon={faBars} color={'#fff'} style={{ marginRight: 20, fontSize: 28 }} />
                    {
                        (customer?.isHalaal) && <TextHalaal>Halaal</TextHalaal>
                    }
                </IconContainerRelative>
                <Link href="/">
                    <LogoContainer>
                        <img src={'/imgs/logo-black.png'} alt="Del Forno - The Home of Good Food" width="121" height="80" />
                    </LogoContainer>
                </Link>
                <Link href="/cart" legacyBehavior>
                    <IconContainer>
                        <CartContainer>
                            <FontAwesomeIcon icon={faCartShopping} color={'#fff'} style={{ fontSize: 28 }} />
                            {cart?.qty > 0 && <CartCount>{cart.qty}</CartCount>}
                            {cart?.total > 0 && <CartTotal>R{(cart.total).toFixed(2)}</CartTotal>}
                        </CartContainer>
                    </IconContainer>
                </Link>
            </GridContainer>
        </HeaderContainer>
    );
}

const TextHalaal = styled.div`
    ${tw`absolute text-white text-xs bottom-2 mr-[12px]`}
`;

const CartCount = styled.div`
    ${tw`flex text-sm bg-red w-[24px] h-[24px] text-grey absolute right-[-8px] top-[-8px] rounded-full items-center justify-center`}
`;

const CartTotal = styled.div`
    ${tw`text-sm text-grey`}
`;

const CartContainer = styled.div`
    ${tw`flex flex-col relative items-center mt-1`}
`;

const IconContainer = styled.div`
    ${tw`flex flex-row justify-center items-center cursor-pointer`}
`;

const IconContainerRelative = styled.div`
    ${tw`flex flex-row justify-center items-center cursor-pointer relative`}
`;

const LogoContainer = styled.div`
    ${tw`flex flex-row justify-center pt-2`}
`;

const HeaderContainer = styled.div`
    ${tw`w-full bg-black h-24`}
`
const GridContainer = styled.div`
    ${tw`grid grid-cols-3`}
`